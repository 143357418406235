<template>
  <div class="home">
    <b-container>
      <div class="row">
        <div class="col-lg-6 col-md-6 m-auto auth-form">
          <h3 class="text-center mb-4 mt-5">{{ pageAttributes.title }}</h3>
          <b-card>
            <div>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <b-form-group label="Enter new password">
                    <ValidationProvider
                      rules="required"
                      name="New Password"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="new_password"
                        size="lg"
                        type="password"
                      ></b-form-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group label="Re-enter new password">
                    <ValidationProvider
                      rules="required|password:@New Password"
                      name="Password confirmation"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="confirmation"
                        size="lg"
                        type="password"
                      ></b-form-input>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>

                  <div class="text-center mt-4">
                    <b-button
                      class="btn-om-solid"
                      type="submit"
                      size="lg"
                      :disabled="loading"
                    >
                      <span
                        class="
                          spinner-border spinner-border-sm
                          position-relative
                        "
                        style="top: -3px"
                        v-show="loading"
                      ></span>
                      {{ pageAttributes.button }}
                    </b-button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </b-card>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", required);
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      new_password: "",
      confirmation: "",
      message: "",
      loading: false,
    };
  },
  computed: {
    pageAttributes() {
      if (this.$route.name === "password.recover") {
        return {
          title: "Reset Password",
          button: "RESET",
        };
      }
      return {
        title: "Set Password",
        button: "SUBMIT",
      };
    },
  },
  methods: {
    onSubmit() {
      let data = {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.new_password,
      };
      this.$store
        .dispatch("auth/resetPassword", data)
        .then(() => {
          this.loading = false;
          this.$bvToast.toast(`Reset Password Successfully`, {
            noCloseButton: true,
            variant: "success",
          });
          this.login();
        })
        .catch((error) => {
          this.loading = false;
          if (error.status === 422) {
            let errors = {
              "New Password": error.data.errors.password,
            };
            this.$refs.form.setErrors(errors);
            if (!error.data.errors.password) {
              let message = error.data.errors.email;
              this.$bvToast.toast(message, {
                noCloseButton: true,
                variant: "danger",
              });
            }
          } else {
            this.$bvToast.toast(`Please Contact Customer Support`, {
              title: "Something Wrong!",
              noCloseButton: true,
              variant: "danger",
            });
          }
        });
    },
    login() {
      let data = {
        email: this.$route.query.email,
        password: this.new_password,
        device_name: "web",
      };
      this.$store
        .dispatch("auth/login", data)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$bvToast.toast(`Please Contact Customer Support`, {
            title: "Something Wrong!",
            noCloseButton: true,
            variant: "danger",
          });
        });
    },
  },
};
</script>
